export interface Answer {
  id: number;
  text: string
}

export interface Theme{
  id: number
  name: string
}

export interface Data{
  tickets: Ticket[]
  categories: Category[]
  themes: Theme[]
}

export interface Ticket {
  correct_answer: number;
  answers: Answer[];
  question: string;
  img: string | null;
  num: number
  categories: number[]
  themes: number[]
}

export interface Category {
  id: number
  name: string
}

export interface Language {
  code: string
  name: string
}

export interface TicketAnswer {
  ticketNum: number
  variantId: number
  isCorrect: boolean
}

export interface DataContext {}

export const Languages: Language[] = [
  {
    code: 'ka',
    name: 'Georgian',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'en',
    name: 'English',
  },
  // FIXME: проблемы с импортом данных
  // {
  //   code: 'ab',
  //   name: 'Abkhazian',
  // },
  {
    code: 'az',
    name: 'Azerbaijani',
  },
  {
    code: 'hy',
    name: 'Armenian',
  },
  {
    code: 'os',
    name: 'Ossetian',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
]

import { FC } from 'react';
import classNames from 'classnames';

export interface MyButtonProps {
  isHl: boolean
  text: string
  align?: 'left' | 'center'

  onClick(): void
}

export const MyButton: FC<MyButtonProps> = ({ isHl, onClick, text, align }) => {
  return (
    <div
      className={classNames(
        'bg-gray-100 rounded-lg py-1 px-2 align-baseline inline-block whitespace-nowrap',
        align ? `text-${align}` : 'text-center',
        'cursor-pointer',
        isHl ? 'bg-blue-100' : null,
      )}
      onClick={onClick}>{text}</div>
  )
}

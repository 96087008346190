import { FC } from 'react';
import { useTranslation } from 'react-i18next';


export const Header: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={'p-4'}>
      <span
        className={'text-4xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-green-500'}
      >
        {t('Traffic Laws')} : {t('Georgia')}
      </span>
    </div>
  )
}

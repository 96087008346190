import { useEffect, useState } from 'react'
import './App.css';
import { Data, Languages, Ticket, TicketAnswer } from './domain/types';
import update from 'immutability-helper'
import i18n from 'i18next';
import { createShuffle } from 'pandemonium/shuffle'
import { initReactI18next, Trans, useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Link, Route, Routes } from 'react-router-dom';
import { TicketList } from './components/ticket/TicketList';
import { TicketListFilter } from './components/ticket/TicketListFIlter';
import { Header } from './components/common/Header';
import { useLocalStorageKey } from './hooks/useLocalStorageKey';
import useLocalStorage from './hooks/useLocalStorage';
import { Selector } from './components/common/Selector';
import { Button } from 'antd';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          'Welcome to React': 'Welcome to React and react-i18next'
        }
      }
    },
    fallbackLng: ['en', 'ru'],
    interpolation: {
      escapeValue: false
    }
  });


interface Preferences {
  lang: string
  category: number | null
  theme: number | null
  status: 'all' | 'new' | 'errors' | 'favorites'
}

export default function App() {
  const [data, setData] = useState<Data>({ themes: [], tickets: [], categories: [] });
  const [loading, setLoading] = useState(true);
  const [currentTickets, setCurrentTickets] = useState<Ticket[]>([]);
  const [shuffleSeed, setShuffleSeed] = useState<number>(0);
  const localStorageKey = useLocalStorageKey()
  const { t, i18n } = useTranslation()
  const ticketsKey = localStorageKey(`ge-tickets`)
  const [savedAnswers, setSavedAnswers] = useLocalStorage<Record<number, TicketAnswer>>(
    ticketsKey,
    {},
  )

  const favoritesKey = localStorageKey(`ge-favorites`)
  const [favorites, setFavorites] = useLocalStorage<number[]>(
    favoritesKey,
    [],
  )

  const prefKey = localStorageKey(`preferences`)
  const [preferences, setPreferences] = useLocalStorage<Preferences>(
    prefKey,
    {
      lang: 'en',
      status: 'all',
      category: null,
      theme: null,
    },
  )

  const setPrefCategory = (catId: number | null) => {
    setPreferences(update(preferences, {
      category: {
        $set: catId,
      },
    }))
  }

  const setPrefLang = (lang: string) => {
    setPreferences(update(preferences, {
      lang: {
        $set: lang,
      },
    }))
  }

  const setPrefTheme = (themeId: number | null) => {
    setPreferences(update(preferences, {
      theme: {
        $set: themeId,
      },
    }))
  }

  const setPrefStatus = (status: 'all' | 'new' | 'errors' | 'favorites') => {
    setPreferences(update(preferences, {
      status: {
        $set: status,
      },
    }))
  }

  const setUnsetCategory = (id: number) => {
    if (preferences.category === null) {
      setPrefCategory(id)
      return
    }

    if (preferences.category === id) {
      setPrefCategory(null)
      return
    }

    setPrefCategory(id)
  }

  const setUnsetTheme = (id: number) => {
    if (preferences.theme === null) {
      setPrefTheme(id)
      return
    }

    if (preferences.theme === id) {
      setPrefTheme(null)
      return
    }

    setPrefTheme(id)
  }

  const setAnswer = (ticket: Ticket, variantId: number) => {
    setSavedAnswers(update(savedAnswers, {
      [ticket.num]: {
        $set: {
          ticketNum: ticket.num,
          variantId: variantId,
          isCorrect: ticket.correct_answer == variantId,
        }
      }
    }))
  }

  const setUnsetFavorite = (ticketNum: number) => {
    const index = favorites.indexOf(ticketNum);
    if (index >= 0) {
      setFavorites(update(favorites, { $splice: [[index, 1]] }))
    } else {
      setFavorites(update(favorites, { $push: [ticketNum] }))
    }
  }

  const changeLang = (lang: string) => {
    setPrefLang(lang)

    for (const language of Languages) {
      if (i18n.resolvedLanguage === lang) {
        i18n.changeLanguage(lang)
        return
      }
    }

    i18n.changeLanguage('en')
  }

  const clearProgress = () => {
    setSavedAnswers({})
  }

  const setShuffle = (is: boolean) => {
    if (is) {
      setShuffleSeed(Math.random())
    } else {
      setShuffleSeed(0)
    }
  }

  useEffect(() => {
    const answers: Record<number, TicketAnswer> = JSON.parse(window.localStorage.getItem(ticketsKey) ?? '{}')

    setSavedAnswers(answers)
  }, [ticketsKey])

  useEffect(() => {
    if (preferences != null) return

    const prefData = window.localStorage.getItem(prefKey)
    const pref: Preferences = JSON.parse(prefData!)

    setPreferences(pref)
    changeLang(pref.lang)
  }, [preferences])

  useEffect(() => {
    if (!preferences.lang) return

    fetch(
      `/db.${preferences.lang}.json`)
      .then((res) => res.json())
      .then((json) => {
        setData(json)
      }).then(() => setLoading(false))
  }, [preferences.lang])

  useEffect(() => {
    let resTickets: Ticket[] = data.tickets;

    if (preferences.category !== null) {
      resTickets = resTickets.filter(ticket => ticket.categories.indexOf(preferences.category!) >= 0)
    }

    if (preferences.theme !== null) {
      resTickets = resTickets.filter(ticket => ticket.themes.indexOf(preferences.theme!) >= 0)
    }

    if (preferences.status !== null) {
      switch (preferences.status) {
        case 'all':
          break;
        case 'errors':
          resTickets = resTickets.filter(ticket => savedAnswers[ticket.num] !== undefined && !savedAnswers[ticket.num].isCorrect)
          break;
        case 'new':
          resTickets = resTickets.filter(ticket => savedAnswers[ticket.num] === undefined)
          break;
        case 'favorites':
          resTickets = resTickets.filter(ticket => favorites.indexOf(ticket.num) !== -1)
          break;
      }
    }

    const s = createShuffle<Ticket>(() => shuffleSeed)
    resTickets = s(resTickets)

    setCurrentTickets(resTickets)
  }, [data, preferences.category, preferences.theme, preferences.status, savedAnswers, shuffleSeed])

  if (loading) {
    return <div>{t('Loading. Please wait')}</div>
  }

  return (
    <>
      <div className="mb-4">
        <Header/>
      </div>

      <div className={'px-2 grid grid-cols-1 md:grid-cols-3 gap-y-2 gap-x-4'}>
        <div className={'col-span-1'}>
          <div className={'grid grid-cols-1 lg:grid lg:grid-cols-1 gap-2'}>
            <Selector
              helpText={t('Select the language')}
              align={'left'}
              mode={'select'}
              selectedId={preferences?.lang}
              items={Languages.map(lng => (
                {
                  id: lng.code,
                  text: lng.name,
                  onClick: () => changeLang(lng.code)
                }
              ))}/>
            <Selector
              helpText={t('Select the category (auto/moto/etc.)')}
              align={'left'}
              mode={'select'}
              selectedId={preferences.category ? preferences.category.toString() : null}
              items={data.categories.map(cat => ({
                id: cat.id.toString(),
                text: cat.name,
                onClick() {
                  setUnsetCategory(cat.id)
                }
              }))}/>
            <Selector
              helpText={t('All - will show all tickets. New - only those tickets that you don`t see before. Errors - tickets that you answered wrong. Favorites - show all favorite tickets.')}
              align={'left'}
              mode={'select'}
              selectedId={preferences.status}
              items={[
                {
                  id: 'all',
                  text: t('All'),
                  onClick() {
                    setPrefStatus('all')
                  }
                },
                {
                  id: 'new',
                  text: t('New'),
                  onClick() {
                    setPrefStatus('new')
                  }
                },
                {
                  id: 'errors',
                  text: t('Errors'),
                  onClick() {
                    setPrefStatus('errors')
                  }
                },
                {
                  id: 'favorites',
                  text: t('Favorites'),
                  onClick() {
                    setPrefStatus('favorites')
                  }
                },
              ]}/>
            <Selector
              helpText={t('Theme in selected category')}
              chooseAll={true}
              align={'left'}
              mode={'select'}
              selectedId={preferences.theme ? preferences.theme.toString() : null}
              items={data.themes
                .sort((a, b) => a.id - b.id)
                .map(thm => ({
                  id: thm.id.toString(),
                  text: `${thm.id}: ${thm.name}`,
                  onClick() {
                    setUnsetTheme(thm.id)
                  }
                }))}/>
            <Button type={'link'} onClick={clearProgress}>{t('Clear progress')}</Button>
            {
              shuffleSeed != 0
                ? <Button type={'link'} onClick={() => setShuffle(false)}>{t('Normalize tickets')}</Button>
                : <Button type={'link'} onClick={() => setShuffle(true)}>{t('Shuffle tickets')}</Button>
            }
          </div>
        </div>
        <div className={'col-span-2'}>
          <Routes>
            <Route path="/"
                   element={<TicketList tickets={currentTickets} setAnswer={setAnswer}
                                        setUnsetFavorite={setUnsetFavorite} favorites={favorites}/>}/>
            <Route path="/ge/tickets/:ticketNum"
                   element={<TicketListFilter tickets={currentTickets} setAnswer={setAnswer}
                                              setUnsetFavorite={setUnsetFavorite} favorites={favorites}/>}/>

            <Route
              path="*"
              element={
                <main style={{ padding: '1rem' }}>
                  <p>
                    <Trans key={'Page not found'}>
                      Page not found. Please, follow to <Link to={'/'}>the main page</Link>.
                    </Trans>
                  </p>
                </main>
              }
            />
          </Routes>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </>
  )
}


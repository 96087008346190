import { FC, useState } from 'react';
import { Button } from 'antd';
import { Ticket } from '../../domain/types';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined } from '@ant-design/icons';


interface Props {
  ticket: Ticket
  isFavorite: boolean

  setAnswer(ticket: Ticket, variantId: number): void

  setUnsetFavorite(ticketNum: number): void
}

const delay = (ms: number): Promise<unknown> => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const TicketCard: FC<Props> = ({ ticket, setAnswer, setUnsetFavorite, isFavorite }) => {
  const { t } = useTranslation()
  const [showResult, setShowResult] = useState(false)
  const [answeredVarId, setAnsweredVarId] = useState<number | null>(null)

  const checkAnswer = async (id: number) => {
    setAnsweredVarId(id)
    setShowResult(true)

    await delay(1000)

    setAnswer(ticket, id)
  }

  return (
    <div className={'p-2 mb-6 border border-blue-300'}>
      {
        ticket.img == null ? null
          : (
            <div className={'mb-4'}>
              <img
                loading={'lazy'}
                alt="example"
                src={'https://traffic-laws.info/img/' + ticket['img']}
              />
            </div>
          )
      }

      <Button
        type="dashed"
        className={'float-left mr-2'}
        size={'small'}
        style={{ marginBottom: '-2px' }}>
        <Link to={`/ge/tickets/${ticket.num}`}>#{ticket.num}</Link>
      </Button>
      <div className={'font-medium mb-4'}>{ticket.question}</div>
      <div className="grid sm:grid-cols-2 xs:grid-cols-1 gap-2 place-content-stretch">
        {
          ticket.answers.map(answer => (
            <div
              key={answer.id}
              className={classNames(
                'border-blue-100 border py-1',
                'cursor-pointer',
                'hover:border-blue-400',
                showResult && answer.id === ticket.correct_answer
                  ? answeredVarId ? 'bg-green-500' : null
                  : null,
                showResult && answer.id !== ticket.correct_answer
                  ? answeredVarId && answeredVarId === answer.id ? 'bg-red-500' : null
                  : null,
              )}
              onClick={() => checkAnswer(answer.id)}
            >
              <div className={'flex items-start'}>
                <div className={'text-2xl px-2'}>{answer.id}</div>
                <div className={'pr-1'}>{answer.text}</div>
              </div>
            </div>
          ))}
      </div>
      <div className={'flex justify-center pt-2'}>
        <Button
          type={'link'}
          icon={isFavorite ? <CheckCircleOutlined/> : null}
          onClick={() => setUnsetFavorite(ticket.num)}>
          {
            isFavorite
              ? t('Remove from Favorites')
              : t('Add to Favorites')
          }
        </Button>
      </div>
    </div>
  )
}

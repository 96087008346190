import { FC } from 'react';
import { Ticket } from '../../domain/types';
import { Link, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { TicketList } from './TicketList';


interface Props {
  tickets: Ticket[],
  favorites: number[]

  setAnswer(ticket: Ticket, variantId: number): void

  setUnsetFavorite(ticketNum: number): void
}

const getTicket = (tickets: Ticket[], ticketNum: number): Ticket[] => {
  return tickets.filter(ticket => ticket.num == ticketNum)
}

export const TicketListFilter: FC<Props> = ({ tickets, setAnswer, setUnsetFavorite, favorites }) => {
  let params = useParams();

  if (params['ticketNum'] == undefined) {
    return (
      <Trans key={'Page not found'}>
        Page not found. Please, follow to <Link to={'/'}>the main page</Link>.
      </Trans>
    )
  }

  const ticketNum = parseInt(params['ticketNum'], 10)
  tickets = getTicket(tickets, ticketNum);

  return (
    <div>
      <div className={'p-4'}>
        <Trans>
          Return to <Link to={'/'}>all tickets</Link> list.
        </Trans>
      </div>
      <TicketList tickets={tickets} setAnswer={setAnswer} setUnsetFavorite={setUnsetFavorite} favorites={favorites}/>
    </div>
  )
}

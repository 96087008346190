import { createRef, FC, useEffect, useState } from 'react';
import { TicketCard } from './TicketCard';
import { Ticket } from '../../domain/types';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';


interface Props {
  tickets: Ticket[],
  favorites: number[]

  setAnswer(ticket: Ticket, variantId: number): void

  setUnsetFavorite(ticketNum: number): void
}

export const TicketList: FC<Props> = ({ tickets, setAnswer, setUnsetFavorite, favorites }) => {
  const { t } = useTranslation()
  const [currentItems, setCurrentItems] = useState<Ticket[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const ticketsRef = createRef<HTMLDivElement>()

  // Y
  const [y, setY] = useState(0);


  // This function calculate X and Y
  const getPosition = () => {
    if (!ticketsRef.current) return
    const y = ticketsRef!.current?.offsetTop;
    setY(y - 100);
  };


  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    window.addEventListener('resize', getPosition);
  }, []);


  const itemsPerPage = 10;
  const paginate = () => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(tickets.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(tickets.length / itemsPerPage));
  }

  const handlePageClick = (event: {selected: number}) => {
    const newOffset = (event.selected * itemsPerPage) % tickets.length;
    setItemOffset(newOffset);
    window.scrollTo(0, y)
  };

  useEffect(getPosition, [ticketsRef]);
  useEffect(paginate, [itemOffset, itemsPerPage, tickets]);
  useEffect(() => {
    setItemOffset(0);
  }, [tickets])


  return (
    <>
      <div className={'max-w-4xl'} ref={ticketsRef}>
        {currentItems.length == 0 ?
          <div className={'font-bold text-center m-10'}>{t('Has no tickets. Check the filter')}</div> : null}

        <div>
          {
            currentItems.map(ticket =>
              <TicketCard
                key={ticket.num}
                ticket={ticket}
                setAnswer={setAnswer}
                setUnsetFavorite={setUnsetFavorite}
                isFavorite={favorites.indexOf(ticket.num) !== -1}
              />
            )
          }
        </div>

        {
          pageCount == 0 ? null
            : (
              <div className={'flex justify-center'}>
                <ReactPaginate
                  className={'relative z-0 inline-flex rounded-md shadow-sm -space-x-px'}
                  breakLabel="..."
                  pageLinkClassName={'px-4 py-2'}
                  breakClassName={'px-4 py-2 border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center border text-sm font-medium'}
                  activeClassName={'z-10 bg-indigo-50 border border-indigo-500 text-indigo-600 relative inline-flex items-center text-sm font-medium'}
                  pageClassName={'border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center border text-sm font-medium'}
                  previousClassName={'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'}
                  nextClassName={'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'}
                  previousLabel={t('previous')}
                  nextLabel={t('next')}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                />
              </div>
            )
        }

      </div>
    </>
  )
}

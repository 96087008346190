import { FC, HTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';
import { MyButton, MyButtonProps } from './MyButton';
import { useTranslation } from 'react-i18next';


export interface Item extends Pick<MyButtonProps, 'text' | 'onClick'> {
  id: string
}

export interface Props extends Pick<MyButtonProps, 'align'>, Pick<HTMLAttributes<any>, 'className'> {
  mode: 'toggle' | 'select'
  selectedId?: string|null
  items: Item[]
  helpText: string
  chooseAll?: boolean
}

export const Selector: FC<Props> = ({ items, align, selectedId, className, mode, helpText, chooseAll }) => {
  const { t } = useTranslation()
  const [hlId, setHlId] = useState<string | null>(null)
  const [showAll, setShowAll] = useState<boolean>(false)

  const onClick = (item: Item | null) => {
    if (item === null) {
      setShowAll(!showAll)
      setHlId(null)
      return
    }

    if (mode == 'toggle') {
      if (hlId === item.id) {
        setShowAll(true)
        setHlId(null)
      } else {
        setShowAll(false)
        setHlId(item.id)
      }

      item.onClick()

      return
    }

    if (mode == 'select') {
      if (hlId === item.id) {
        setShowAll(!showAll)
        return
      }

      setHlId(item.id)
      setShowAll(false)
      item.onClick()

      return
    }
  }

  useEffect(() => {
    if (!selectedId) return

    setHlId(selectedId)
    setShowAll(false)
  }, [selectedId])


  if (!showAll) {
    if (hlId === null) {
      return (
        <div className={classNames(
          className,
        )}>
          <div>
            {helpText}
          </div>
          <MyButton
            align={align}
            isHl={true}
            text={t('All')}
            onClick={() => onClick(null)}/>
        </div>
      )

    }

    const selectedItem = items.find(item => item.id === hlId)!
    return (
      <div className={classNames(
        className,
      )}>
        <div>
          {helpText}
        </div>
        <MyButton
          align={align}
          isHl={hlId === selectedItem.id}
          text={selectedItem.text}
          onClick={() => onClick(selectedItem)}/>
      </div>
    )
  }

  return (
    <div className={classNames(
      'grid grid-cols-2 gap-2',
      className,
    )}>
      {chooseAll && (
        <MyButton
          align={align}
          isHl={hlId === null}
          text={t('All')}
          onClick={() => onClick(null)}/>
      )}
      {items.map(item => (
        <MyButton
          key={item.id}
          align={align}
          isHl={hlId === item.id}
          text={item.text}
          onClick={() => onClick(item)}/>
      ))}
    </div>
  )
}
